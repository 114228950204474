import { useRef } from 'react'
import styled from 'styled-components'

import { StatusResponseEnum } from '@/api'
import { BookingTypeEnum } from '@/api/bookings'
import {
	BookingService,
	ParallelBookingItem,
} from '@/api/services/booking.service'
import { confirmDialog } from '@/components/ConfirmDialog'
import { DeleteIcon, EditIcon } from '@/components/icons'
import { BookingDate } from '@/components/layout/Sidebar/Bookings'
import { bookingDialog } from '@/components/shared/booking/form/BookingModal'
import { useToast } from '@/components/shared/toast/useToast'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { useProject } from '@/hooks/useProject'
import { translate } from '@/i18n'
import { easePoly } from 'd3-ease'
import { ErrorBoundary } from 'react-error-boundary'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import create from 'zustand'
import CloseIcon from './icons/CloseIcon'
import { animated, useTransition } from '@react-spring/web'

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 8px 16px;
`

type MultiBookingViewStore = {
	isOpen: boolean
	bookings: Array<any>
	name: string
	onClose: () => void
}

type MultiBookingViewProps = {
	bookings: Array<any>
	name: string
}

export const useMultiBookingViewStore = create<MultiBookingViewStore>(
	(set) => ({
		isOpen: false,
		bookings: [],
		name: '',
		onClose: () => {
			set({
				bookings: [],
				name: '',
				isOpen: false,
			})
		},
	}),
)

export const multiBookingsView = ({
	bookings = [],
	name,
}: MultiBookingViewProps) => {
	useMultiBookingViewStore.setState({
		bookings,
		name,
		isOpen: true,
	})
}

const MultiBookingsView = () => {
	const bookings = useMultiBookingViewStore((state) => state.bookings)
	const name = useMultiBookingViewStore((state) => state.name)
	const isOpen = useMultiBookingViewStore((state) => state.isOpen)
	const onClose = useMultiBookingViewStore((state) => state.onClose)

	const transitions = useTransition(isOpen, {
		from: { opacity: 0, translateY: 100, translateX: '-50%' },
		enter: { opacity: 1, translateY: 0, translateX: '-50%' },
		leave: { opacity: 0, translateY: 100, translateX: '-50%' },
		config: {
			duration: 300,
			easing: easePoly.exponent(2),
		},
	})

	const refBody = useRef(null)

	useOnClickOutside(refBody, () => {
		onClose()
	})

	return transitions((style, item) =>
		item ? (
			<Wrapper style={style} ref={refBody}>
				<Header>
					<Title>{name}</Title>
					<div onClick={onClose} style={{ cursor: 'pointer' }}>
						<CloseIcon color="#000" />
					</div>
				</Header>
				<SectionsWrapper>
					<Table>
						<tbody>
							{bookings.map((booking) => (
								<BookingItem key={booking.booking_id} item={booking} />
							))}
						</tbody>
					</Table>
				</SectionsWrapper>
			</Wrapper>
		) : (
			''
		),
	)
}

const BookingItem = ({ item }) => {
	const queryClient = useQueryClient()
	const { workspaceId, projectId } = useProject()
	const { enqueueToast } = useToast()
	const intl = useIntl()
	const handleEdit = () => bookingDialog({ bookingId: Number(item.booking_id) })
	const handleDelete = (e) => {
		return confirmDialog({
			title: intl.formatMessage({ id: 'delete-booking' }),
			message: intl.formatMessage({ id: 'delete-booking-confirm' }),
			onSubmit: removeBooking,
		})
	}

	const removeBooking = async () => {
		try {
			const response = await BookingService.removeBooking({
				workspaceId,
				projectId,
				bookingId: Number(item.id),
			})

			if (response && response.data.status === StatusResponseEnum.Success) {
				queryClient.refetchQueries(['bookings'])

				// remove cache data for map
				queryClient.refetchQueries('bookings_for_layer')

				enqueueToast(
					{
						title: intl.formatMessage({ id: 'success' }),
						message: intl.formatMessage({ id: 'booking-deleted' }),
					},
					{ variant: 'success' },
				)
			}
		} catch (e) {
			enqueueToast(
				{
					title: intl.formatMessage({ id: 'error' }),
					message: intl.formatMessage({ id: 'failed-to-delete-booking' }),
				},
				{ variant: 'error' },
			)
		}
	}

	return (
		<tr>
			<td>{item.display}</td>
			<td>{getBookingTypeName(item)}</td>
			<td>{item.start && <BookingTime item={item} />}</td>
			<td>
				<Row>
					<ActionButton onClick={handleEdit}>
						<EditIcon color="#2C2C2C" />
					</ActionButton>
					<ActionButton onClick={handleDelete}>
						<DeleteIcon color="#2C2C2C" />
					</ActionButton>
				</Row>
			</td>
		</tr>
	)
}

const BookingTime = ({ item }) => {
	const type = getBookingType(item)
	const translated =
		type === BookingTypeEnum.Constant
			? 'constant'
			: type === BookingTypeEnum.Recurent
			? 'recurrent'
			: 'common'

	return (
		<ErrorBoundary fallback={<div>{translate('date-display-error')}</div>}>
			<BookingDate
				type={translated}
				gap={item.gap}
				date={{ start: item.start, end: item.end }}
			/>
		</ErrorBoundary>
	)
}

export default MultiBookingsView

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	margin: 0 8px 16px 8px;

	th:last-child,
	td:last-child {
		width: 70px;
	}

	th,
	td {
		padding: 4px 8px;
		border-collapse: collapse;
		vertical-align: middle;
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	th {
		color: #000;
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
	}

	td {
		white-space: nowrap;

		& > div {
			display: flex;
			align-items: center;
		}
	}
`

const ActionButton = styled.div`
	cursor: pointer;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Row = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`

const Title = styled.div`
	font-size: 1.6rem;
	line-height: 2.2rem;
	text-align: center;
	color: #000000;
`

const Wrapper = styled(animated.div)`
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	max-height: 100%;
	max-width: 768px;
	min-height: 90px;
	width: 100%;
	z-index: 9101;
	overflow-y: auto;

	background: #ffffff;
	box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
`

const SectionsWrapper = styled.div`
	display: flex;
	justify-content: space-around;
`

const getBookingTypeName = (item: ParallelBookingItem) => {
	const type = getBookingType(item)

	if (type === BookingTypeEnum.Recurent) {
		return 'Еженедельная'
	}
	if (type === BookingTypeEnum.Constant) {
		return 'Постоянная'
	}
	return 'Обычная'
}

const getBookingType = (item: ParallelBookingItem) => {
	return item.rec
		? BookingTypeEnum.Recurent
		: item.end
		? BookingTypeEnum.Regular
		: BookingTypeEnum.Constant
}
