import { API } from '@/api'
import { multiBookingsView } from '@/components/MultiBookingsView'
import {
	defaultMapPointImage,
	getImageLink,
} from '@/components/shared/map/point/Point'
import { getMapCoord } from '@/components/shared/map/polygon/Polygon'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Group, Rect, Text } from 'react-konva'
import useImage from 'use-image'

const useMapAvatar = (bookingData, node, apiUrl) => {
	const imgSrc = bookingData
		? API.user.getAvatar({ userId: Number(bookingData.user_id), url: apiUrl })
		: getImageLink(node?.icon, apiUrl) ||
		  getImageLink(defaultMapPointImage, apiUrl)
	const [pointImg] = useImage(imgSrc, 'anonymous')

	return { image: pointImg }
}

const getNoun = (number, txt, cases = [2, 0, 1, 1, 1, 2]) =>
	txt[
		number % 100 > 4 && number % 100 < 20
			? 2
			: cases[number % 10 < 5 ? number % 10 : 5]
	]

const MultiBookingsPoint = (props) => {
	const {
		point,
		options,
		bookingData,
		allBookings: bookingsArr,
		available,
		node,
		apiUrl,
	} = props

	const bookingsCount = bookingsArr.length
	const names = `${bookingsCount} ${getNoun(bookingsCount, [
		'бронь',
		'брони',
		'броней',
	])}`

	const layers = useMapStore((state) => state.layers)
	const setSeat = useGlobalStore((state) => state.setSeat)
	const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
	const [width, height] = useMapStore((state) => state.size)
	const setTooltip = useMapStore((state) => state.setTooltip)

	const textRef = useRef<any>(null)
	const groupRef = useRef<any>(null)
	const [coord, setCoord] = useState({ x: 0, y: 0, pX: 0 })

	const { x, y, name } = point
	const { labelSize, fontSize, color, borderWidth, wrapText } = options

	const isEmployeeVisible = layers['employees']
	const { image } = useMapAvatar(
		isEmployeeVisible ? bookingData : null,
		node,
		apiUrl,
	)
	const username = bookingData?.display
		? bookingData.display.split(' ').slice(0, 2).join(' ')
		: null
	const text = isEmployeeVisible ? username || name : name

	const displayText = wrapText ? text.split(' ').join('\n') : text

	const onSelect = useCallback(() => {
		multiBookingsView({ bookings: bookingsArr, name })
	}, [setSeat, setSeatEmployee, bookingData, point])

	const onMouseEnterHandler = React.useCallback((e) => {
		const container = e.target.getStage()?.container()

		if (container) {
			container.style.cursor = 'pointer'
		}
	}, [])

	const onMouseLeaveHandler = React.useCallback((e) => {
		const container = e.target.getStage()?.container()

		if (container) {
			container.style.cursor = 'default'
		}
	}, [])

	const onGroupMouseEnterHandler = React.useCallback(
		(e) => {
			setTooltip(name)
		},
		[setTooltip, name],
	)

	const onGroupMouseLeaveHandler = React.useCallback(
		(e) => {
			setTooltip(null)
		},
		[setTooltip],
	)

	useEffect(() => {
		if (width > 0 && height > 0) {
			const pointX =
				-textRef.current?.textWidth / 2 + (width * labelSize) / 2 ||
				(width * labelSize) / 2
			setCoord({
				x: getMapCoord(width, x),
				y: getMapCoord(height, y),
				pX: Number(pointX),
			})
		}
	}, [textRef.current, image, width, height, displayText])

	useEffect(() => {
		if (!groupRef.current) return
		groupRef.current.on('mouseenter', onGroupMouseEnterHandler)
		groupRef.current.on('mouseleave', onGroupMouseLeaveHandler)
	}, [groupRef.current])

	return (
		<>
			<Group
				x={coord.x}
				y={coord.y}
				offsetX={(width * labelSize) / 2}
				offsetY={(width * labelSize) / 2}
				onClick={onSelect}
				onTap={onSelect}
				listening={true}
			>
				<Group
					onMouseEnter={onMouseEnterHandler}
					onMouseLeave={onMouseLeaveHandler}
					opacity={available ? 1 : 0.3}
					id={'point' + point.id}
					ref={groupRef}
				>
					<Rect
						width={width * labelSize}
						height={width * labelSize}
						stroke="#000"
						strokeWidth={fontSize * borderWidth}
						fill="#fff"
						cornerRadius={node?.radius}
						shadowForStrokeEnabled={false}
						perfectDrawEnabled={false}
						listening={available}
					/>
					<Text
						width={width * labelSize}
						height={width * labelSize}
						ref={textRef}
						text={bookingsCount}
						align="center"
						verticalAlign="middle"
						fontSize={fontSize * 1.5}
						fill="#000"
						listening={true}
						perfectDrawEnabled={false}
					/>
					<Text
						ref={textRef}
						text={names}
						align="center"
						fontSize={fontSize}
						fill={color}
						y={width * 1.1 * labelSize}
						x={coord.pX}
						listening={available}
						perfectDrawEnabled={false}
					/>
				</Group>
			</Group>
		</>
	)
}

export default MultiBookingsPoint
